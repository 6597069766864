<template lang="pug">
	extends ./table.pug

	block tableTH
		template(v-slot:cell(info)='data')
			.vuetable__info(:id="!isMobile ? `info-${data.item.id}` : ''" @click="isMobile ? $bvModal.show(`info-${data.item.id}`) : ''")
				svg(width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg")
					path(d="M16.5 9.5C16.5 5.375 13.125 2 9 2C4.875 2 1.5 5.375 1.5 9.5C1.5 13.625 4.875 17 9 17C13.125 17 16.5 13.625 16.5 9.5Z" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
					path(d="M8.99609 6.5H9.00283" stroke="#52A7F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
					path(d="M8.99609 9.5V13.25" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")

			b-tooltip(v-if="!isMobile" :target="`info-${data.item.id}`" no-fade placement="bottom" triggers="hover")
				info-tooltip(:item="data.item")

			b-modal(v-if="isMobile" :id="`info-${data.item.id}`" content-class="modal-indents modal-followers" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
				template(#modal-header="{ close }")
					h3.h3(v-html="isMobile ? $t('finance.subscription') : ''")
					icon-close(@click="close()")
				info-tooltip(:item="data.item")

		template(v-slot:cell(id)='data')
			p.mb-2.b3(v-if="data.item.id") {{ data.item.id }}
			p.mb-0.b4.text-grey.text-nowrap(v-if="data.item.createdAt") {{ data.item.createdAt | dateDDMMMYYYYHHmm }}

		template(v-slot:cell(plan)='data')
			span.b3.d-block.followers-table__plan(v-if="data.item.plan && data.item.plan.title" :id="`item${data.item.id}${data.item.plan.id}`") {{ data.item.plan.title }}
			div
				span.b3.text-bold.mr-2(v-if="data.item.plan && data.item.plan.price" v-html="data.item.plan.price.priceWithCurrency()")
				span.b4.mr-2.text-primary.vuetable__discount.followers-table__discount(
					v-if="data.item.plan && data.item.promotion"
					v-html="`-${data.item.promotion.discount}%`"
					:id="!isMobile ? `promotionTooltip-${data.item.id}` : ''"
					@click="isMobile ? $bvModal.show(`promotionTooltip-${data.item.id}`) : ''"
				)
				span.b4.mr-2.text-primary.vuetable__discount.followers-table__discount(
					v-if="data.item.plan && data.item.promoCode"
					v-html="`-${data.item.promoCode.discount}%`"
					:id="!isMobile ? `promoСodeTooltip-${data.item.id}` : ''"
					@click="isMobile ? $bvModal.show(`promoСodeTooltip-${data.item.id}`) : ''"
				)

				span.b4.mr-2.text-success.vuetable__discount.followers-table__discount(
					v-if="data.item.plan && data.item.extraPrice"
					v-html="`+${data.item.extraPrice.priceWithCurrency()}`"
					:id="!isMobile ? `overPriceTooltip-${data.item.id}` : ''"
					@click="isMobile ? $bvModal.show(`overPriceTooltip-${data.item.id}`) : ''"
				)

				span.b4.mr-2.is-comebacker.vuetable__discount.followers-table__discount(
					v-if="data.item && data.item.isPaymentPaidAfterUseComebacker()"
					v-html="`-${data.item.comebacker.discount}%`"
					:id="!isMobile ? `comebackerTooltip-${data.item.id}` : ''"
					@click="isMobile ? $bvModal.show(`comebackerTooltip-${data.item.id}`) : ''"
				)

			span.b4.text-grey(v-if="data.item.plan && data.item.plan.period && data.item.plan.period.title != 'forever'") {{ $t('plan.once') }} {{ !data.item.plan.isForever() ? $t('plan.periods.' + data.item.plan.period.title) : serviceString.ucFirst($t('plan.periods.' + data.item.plan.period.title)) }}
			span.b4.text-grey(v-if="data.item.plan && data.item.plan.period && data.item.plan.period.title == 'forever'") {{ !data.item.plan.isForever() ? $t('plan.periods.' + data.item.plan.period.title) : serviceString.ucFirst($t('plan.periods.' + data.item.plan.period.title)) }}

			modal-tooltip(
				v-if="data.item.promotion"
				:id="`promotionTooltip-${data.item.id}`"
				:classTooltip="'promotions-tooltip'"
				:href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/akcii'"
				:textLink="`<svg class='mr-2' xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'><path stroke='#52A7F9' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M15 22c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6ZM15.75 9h-7.5M15.75 15h-7.5'/></svg>${$t('promotions.tabs.stock')}`"
				:title="$t('plan.purchasedPromotion')"
				:text="`${$t('paymentConfirm.promotion')}: ${data.item.promotion.title}`"
				:textMobile="data.item.promotion.title"
			)

			modal-tooltip(
				v-if="data.item.promoCode"
				:id="`promoСodeTooltip-${data.item.id}`"
				:classTooltip="'promotions-tooltip'"
				:href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/promokody'"
				:textLink="`<svg class='mr-2' xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'><path stroke='#52A7F9' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M15 22c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6ZM15.75 9h-7.5M15.75 15h-7.5'/></svg>${$t('promotions.tabs.promoCodes')}`"
				:title="$t('plan.promoCodeUsed')"
				:text="`${$t('paymentPage.promoCode')}: ${data.item.promoCode.code}`"
				:textMobile="data.item.promoCode.code"
			)

			modal-tooltip(
				v-if="data.item.comebacker"
				:id="`comebackerTooltip-${data.item.id}`"
				:classTooltip="'comebacker-tooltip'"
				:href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/comebacker'"
				:textLink='`<span class="icon"><svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.15686 13.6489C4.91853 12.8514 4.7627 11.9714 4.7627 10.9998C4.7627 6.60893 8.31936 3.05227 12.7102 3.05227C17.101 3.05227 20.6577 6.60893 20.6577 10.9998C20.6577 15.3906 17.101 18.9473 12.7102 18.9473C11.0785 18.9473 9.55686 18.4523 8.29186 17.6089" stroke="#B971F3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.37634 14.7852L2.33301 12.136" stroke="#B971F3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.37695 14.7852L7.63195 11.696" stroke="#B971F3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></span>${$t("promotions.tabs.comebacker")}`'
				:title="$t('plan.usedComebacker')"
				:text="`${$t('promotions.tabs.comebacker')}: ${data.item.comebacker.title}`"
				:textMobile="data.item.comebacker.title"
			)

			modal-tooltip(
				v-if="data.item.extraPrice"
				:id="`overPriceTooltip-${data.item.id}`"
				:classTooltip="'promotions-tooltip'"
				:href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/tarify#platyat-bolshe'"
				:textLink="`<svg class='mr-2' xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'><path stroke='#52A7F9' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M15 22c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6ZM15.75 9h-7.5M15.75 15h-7.5'/></svg>${$t('tutorials.payMore.title')}`"
				:title="$t('plan.userPaysMore')"
				:text="$t('tutorials.payMore.desc3')"
				:textMobile="$t('tutorials.payMore.desc3')"
			)

		template(v-slot:cell(status)='data')
			p.mb-0.b3.vuetable__status(
				:class="{'text-success': data.item.isActive() && !data.item.isUndefined(),'text-primary': data.item.isStatusTrial(), 'text-grey mb-2': data.item.isCompleted() || data.item.isCancelledButNotKicked() || data.item.isCompletedSubscription(), 'text-orange': data.item.isUndefined()}"
			) {{ status(data.item) }}

			p.b4.mb-0.text-grey.mt-2(v-if="(data.item.nextAt && data.item.nextAt == data.item.kickAt && !data.item.isOnSupportCriticalAndUnknown()) && !data.item.isOneTime() ") {{ $t('forms.until') }} {{ data.item.nextAt | dateDDMMMYYYY }}
			p.b4.mb-0.text-grey.mt-2(v-else-if="data.item.kickAt") {{ $t('forms.until') }} {{ data.item.kickAt | dateDDMMMYYYY }}
			p.b4.mb-0.text-grey.mt-2(v-else-if="data.item.kickedAt") {{ $t('forms.until') }} {{ data.item.kickedAt | dateDDMMMYYYY }}
			p.b3.mb-0.d-flex.align-items-center.text-nowrap.text-grey.mt-2(v-if="data.item.isOnSupport() || data.item.isOnSupportCriticalAndUnknown()") {{ $t('plan.nextPaymentInSupport') }}
				span.b3.mb-0.ml-2.vuetable__tooltip(:id="!isMobile ? `nextPaymentNull-${data.item.id}` : ''" @click="isMobile ? $bvModal.show(`nextPaymentNull-${data.item.id}`) : ''") ?
			p.mb-0.b4.text-grey.mt-2(v-if="data.item.nextAt && data.item.trial") {{ $t('plan.free') }}
			//p.mb-0.b3.text-grey(v-if="data.item.isCompleted()") {{ $t('plan.nextPaymentNull') }}

			modal-tooltip(
				v-if="data.item.isOnSupport() || data.item.isOnSupportCriticalAndUnknown()"
				:id="`nextPaymentNull-${data.item.id}`"
				:classTooltip="'promotions-tooltip'"
				:href="'https://docs.paywall.pw/rus/obshee/vosstanovlenie-podpisok'"
				:textLink="`<div class='status-dollar-circle bg-white mr-1'></div>&nbsp${$t('tutorials.recoverySubscription')}`"
				:title="$t('plan.nextPaymentInSupport')"
				:text="$t('tutorials.recoverySubscriptionDesc')"
				:textMobile="$t('tutorials.recoverySubscriptionDesc')"
			)

		template(v-slot:cell(paymentsSum)='data')
			.d-flex.flex-column
				span.b3.text-nowrap.followers-table__income(v-if='data.item.counters && data.item.counters.other && data.item.counters.other.count > 0 && data.item.counters.other.toMerchant' v-html='data.item.counters.other.toMerchant.priceWithCurrency()')
				span.followers-table__income(v-if='data.item.counters && data.item.counters.world2ru && data.item.counters.world2ru.count > 0 && data.item.counters.world2ru.toMerchant' v-html='data.item.counters.world2ru.toMerchant.priceWithCurrency()')
				span.b3.text-nowrap.followers-table__income(v-if='data.item.counters && data.item.counters.other && data.item.counters.other.count == 0 && data.item.counters.world2ru && data.item.counters.world2ru.count == 0' v-html="`0&nbsp;${currency[data.item.price.currency]}`")

		template(v-slot:cell(btn)='data')
			b-btn.badge-pill.pointer.mb-1(variant='secondary' size='xs' @click='sendLink(data.item.id)') {{ $t('project.sendLink') }}
		template(v-slot:cell(user)='data')
			p.mb-2
				user-short-data(v-if='data.item.user && data.item.user.id' :item='data.item.user')
				span.text-warning(v-else) {{ $t('user.unknow') }}
			p.mb-0.text-sub.text-nowrap {{ data.item.email }}
</template>

<script>
import {bus} from '@/main.js';
import {mapGetters, mapActions} from 'vuex';
import TableMixin from './TableMixin';
import TableSearch from './TableSearch';
import TableShowMore from './TableShowMore';
import UserShortData from '@/components/Common/UserShortData';
import ModalTooltip from '@/components/Modals/ModalTooltip';
import {SERVICE_STRING} from '@/utils/string';
import {CURRENCY} from '@/models/money';
import InfoTooltip from '@/components/Common/InfoTooltip';
import IconClose from "@/components/Common/IconClose";

export default {
	name 		: 'TableFollowers',
	mixins 		: [
		TableMixin
	],
	components 	: {
		TableSearch,
		TableShowMore,
		UserShortData,
		ModalTooltip,
		InfoTooltip,
		IconClose
	},
	props : {
		role: {
			type: String,
			default: 'item'
		},
		filters: {
			type: Object,
			default: () => ({})
		},
		type: {
			type: String,
			default: null
		}
	},
	data () {
		return {
			sortBy 			: 'createdAt',
			searchKeys 		: ['createdAt'],
			noLocalSorting: true,
			sorting			: (ctx) => {
				let data 		= {};
				data[`order[${ctx.sortBy}]`] = 'desc';

				this.isbusy = true;
				this.getItems( data );
			},
			fields		: [
				{
					key: 'info',
					label: '',
				},
				{
					key: 'id',
					label: this.$t('project.id'),
				},
				{
					key: 'user',
					label: this.$t('project.follower'),
				},
				{
					key: 'plan',
					label : this.$t('plan.title'),
				},
				{
					key: 'status',
					label: this.$t('project.status'),
				},
				{
					key: 'paymentsSum',
					label: this.$t('project.profit'),
				},
				{
					key: 'paymentsSum',
					label: this.$t('project.profitRu'),
				}
			],
			items 		: [],
		}
	},
	mounted() {
		this.getItems = _.debounce( ( data = {} ) => {
			if ( this.filters.query && this.filters.query.value != null ) {
				this.filters.query.value = this.filters.query.value.replace(/^\@/g, '');
			}

			this.get(_.extend(this.parseFilters(), {page: this.currentPage, itemsPerPage: this.perPage}, data))
				.then( v => {
					if ( this.currentPage == 1 ) this.items = [];

					this.items = this.items.concat(v);
					this.isbusy = false;
					this.$emit('onGetItems', v);
				})
				.catch( v => this.isbusy = false );
		}, 500);

		bus.$on('tableFollowersExport', () => {
			this.exportSubscriptions()
		});
		bus.$on('tableFollowersRefresh', (query) => {
			this.searching(query);
		});

		this.searching();
	},
	created() {
		this.searching = _.debounce( ( query ) => {
			this.sorting(this);
		}, 400);
	},
	destroyed() {
		bus.$off('tableFollowersRefresh');
		bus.$off('tableFollowersExport');
		this.$store.commit('finance/setSubscriptionsTotal', 0);
	},
	computed: {
		...mapGetters({
			totalItems	: 'finance/subscriptionsTotal',
			isPending 	: 'finance/isPendingSubcriptions',
		}),
		serviceString() {
			return SERVICE_STRING;
		},
		currency() {
			return CURRENCY;
		},
	},
	methods: {
		...mapActions({
			get: 'finance/subscriptions',
			exportSubs: 'finance/exportSubscriptions',
		}),
		localSorting(ctx) {},
		paymentsAmountRu(v) {
			return v + ' ' + SERVICE_STRING.ru_ends(v, this.$t('project.paymentsArray'));
		},
		status(item) {
			if( item.isUndefined() ) return this.$t('statusesFollowers.unknown');
			if( item.isStatusTrial() ) return this.$t('statusesFollowers.trial');
			if( item.isActive() ) return this.$t('statusesFollowers.active');
			if( item.isCompleted() || item.isCancelledButNotKicked() || item.isCompletedSubscription() ) return this.$t('statusesFollowers.closed');

			return '';
		},
		exportSubscriptions () {
			let options = {};

			_.each(this.filters, (f, key) => {
				options[key] = f.value || f.id;
			})

			options.page = 1;

			if ( this.currentPage > 1 ) {
				options.itemsPerPage = this.currentPage * options.itemsPerPage;
			}


			options[`order[${this.sortBy}]`] = this.sortDesc ? 'desc' : 'asc';
			this.exportSubs(options);
		}
	}
}
</script>

<style lang='scss' scoped>
.followers-table {
	&__plan {
		margin-bottom: 1px;
	}

	&__discount {
		display: inline-block;
		line-height: 21px !important;
		padding: 0 6.5px;
		border-radius: 6px;
		margin-bottom: 1px;

		&.is-comebacker {
			color: var(--brand-light-gradient-secondary) !important;
			background-color: var(--status-rotate-left);
		}

		&.text-success {
			background-color: var(--brand-bg-success);
		}
	}

	&__income {
		margin-bottom: 7px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.badge {
	padding-top: 4px;
	padding-bottom: 4px;
}
</style>
