<template lang='pug'>
	a.d-flex.align-items-center.user-short-data(:href="'https://t.me/' + item.tgUsername" target="_blank" v-if='item && item.id')
		.d-flex.justify-content-center.align-items-center.user-short-data__avatars(:class="{'brand-gradient': !item.avatar}")
			img(v-if="item.avatar" :src="item.avatar.contentUrl")
			p.mb-0(v-if="!item.avatar") {{ `${item.tgUsername}`.slice(0,1) }}
		span.b3 {{ item.tgUsername }}
</template>
<script>
export default {
	name: 'UserShortData',
	props: {
		item: {
			type: Object,
			default: null
		}
	}
}
</script>

<style lang='scss' scoped>
.user-short-data {
	&__avatars {
		width: 20px;
		min-width: 20px;
		height: 20px;
		min-height: 20px;
		border-radius: 50%;
		margin-right: 6px;

		p {
			font-size: 12px;
			line-height: 12px;
			text-transform: uppercase;
			color: var(--foreground-color);
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}

		&.brand-gradient {
			background: var(--brand-gradient-primary, linear-gradient(227deg, #0071F4 0%, #66B4FF 100%));
		}
	}
}
</style>
