<template lang='pug'>
	span.d-flex.justify-content-between.align-items-center.cursor-pointer.position-relative.pw-btn(
		:class="[{'right-text': rightText}, `${icon}`, `${variant}`]"
	)
		span.b3.mr-1.pw-btn__txt(v-html="text")
		span.b3.text-orange.position-absolute.pw-btn__quantity(v-if="quantity") {{ quantity }}
		svg.ml-2(v-if="!rightText" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg")
			path(d="M10.7129 3.75L15.7507 9L10.7129 14.25" stroke="#52A7F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
			line(x1="14.5488" y1="9.27344" x2="3.24903" y2="9.27344" stroke="#52A7F9" stroke-width="2" stroke-linecap="round")
		span.b3.text-primary(v-if="rightText" v-html="rightText")
</template>

<script>

export default {
	name: 'PwBtn',
	props: {
		text: {
			default : '',
			type: String
		},
		rightText: {
			default : '',
			type: String
		},
		icon: {
			default: '',
			type: String
		},
		quantity: {
			default: null,
			type: Number
		},
		variant: {
			default: '',
			type: String
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.pw-btn {
	padding: 14px 34px;
	color: var(--main-text-color);
	background-color: var(--light-primary);
	border-radius: var(--border-radius-rounded);
	transition: var(--animation-time-short);

	&.right-text {
		padding: 13px 20px;
	}

	&:hover,
	&:active {
		background-color: var(--foreground-color);
		box-shadow: var(--box-shadow-hover);

		.pw-btn__quantity {
			background-color: var(--grayscale-dark-light-back);
		}
	}

	@include respond-below(sm) {
		padding: 14px 26px;

		&.right-text {
			padding: 13px 17px;
		}
	}

	&__quantity {
		top: 50%;
		right: 48px;
		padding: 0 10px;
		border-radius: var(--border-radius-rounded);
		transform: translateY(-50%);
		background-color: var(--grayscale-dark-white-back);
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
	}

	&.smiley {
		padding: 14px 25px;

		span {
			font-size: 16px !important;
			line-height: 21px !important;
		}
	}

	&.icon-telegram {
		position: relative;
		padding: 14px 16px 14px 54px;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 20px;
			width: 24px;
			height: 24px;
			background-image: url('/assets/img/current/telegram.svg');
			background-size: cover;
			transform: translateY(-50%);
		}
	}

	&.grey {
		padding: 15px 24px;
		background-color: var(--bg-back);

		&:hover,
		&:active {
			background-color: var(--foreground-color);
		}

		@include respond-below(sm) {
			padding: 16px 17px;
		}
	}

	&.followers-filters {
		.pw-btn__txt {
			font-size: 16px !important;
			line-height: calc(24 / 16) !important;
		}
	}
}
</style>
