<template lang="pug">
	.info-tooltip
		.border-rounded.info-tooltip__subscription
			.d-flex.align-items-center.info-tooltip__item
				svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
					rect(width='32' height='32' rx='16' :fill="isDarkColor ? '#2C3C4B' : '#EFF7FF'")
					path(d='M23.5 16C23.5 11.875 20.125 8.5 16 8.5C11.875 8.5 8.5 11.875 8.5 16C8.5 20.125 11.875 23.5 16 23.5C20.125 23.5 23.5 20.125 23.5 16Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M15.9961 13H16.0028' stroke='#52A7F9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
					path(d='M15.9961 16V19.75' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
				span.b1.text-bold.ml-10 {{ $t('project.aboutSubscription')}}
			.info-tooltip__item
				span.b3.text-grey.mr-1(ref='id') {{ $t('project.id') }}:
				span.b3(ref='id_value' v-html="item.id")
			.info-tooltip__item
				span.b3.text-grey.mr-1(ref='status') {{ $t('project.status') }}:
				span.b3.vuetable__status.info-tooltip__status-subscription(ref='status_value' :class="{'text-success': item.isActive(), 'text-primary': item.isStatusTrial(), 'text-grey': item.isCompleted() || item.isCancelledButNotKicked(), 'text-orange': item.isUndefined()}" v-html="status(item)")
			div
				span.b3.text-grey.mr-1(ref='framed') {{ $t('project.framed') }}:
				span.b3(ref='framed_value') {{ item.createdAt | dateDDMMMYYYY }}

		.border-rounded.info-tooltip__subscription
			.d-flex.align-items-center.info-tooltip__item
				svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
					rect(width='32' height='32' rx='16' :fill="isDarkColor ? '#2C3C4B' : '#EFF7FF'")
					path(fill-rule='evenodd' clip-rule='evenodd' d='M16 16C13.9289 16 12.25 14.3211 12.25 12.25C12.25 10.1789 13.9289 8.5 16 8.5C18.0711 8.5 19.75 10.1789 19.75 12.25C19.75 14.3211 18.0711 16 16 16Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M22.4426 23.5C22.4426 20.5975 19.5551 18.25 16.0001 18.25C12.4451 18.25 9.55762 20.5975 9.55762 23.5' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
				span.b1.text-bold.ml-10 {{ $t('project.follower')}}
			.info-tooltip__item
				span.b3.text-grey.mr-1(ref='follower') {{ $t('project.follower') }}:
				span.b3(ref='follower_value')
					span(v-if='item.user' v-html="item.user.username")
			div
				span.b3.text-grey.mr-1(ref='email') {{ $t('user.email') }}:
				span.b3(ref='email_value' v-html="item.email")

		.border-rounded.info-tooltip__subscription
			.d-flex.align-items-center.info-tooltip__item
				svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
					rect(width='32' height='32' rx='16' :fill="isDarkColor ? '#2C3C4B' : '#EFF7FF'")
					path(d='M18.25 23.5C22 23.5 23.5 22 23.5 18.25V13.75C23.5 10 22 8.5 18.25 8.5H13.75C10 8.5 8.5 10 8.5 13.75V18.25C8.5 22 10 23.5 13.75 23.5H18.25Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M18.8125 13.75H13.1875' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M18.8125 18.25H13.1875' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
				span.b1.text-bold.ml-10 {{ $t('plan.title')}}
			.info-tooltip__item
				span.b3.text-grey.mr-1(ref='title') {{ $t('forms.name') }}:
				span.b3(ref='title_value' v-html="item.plan.title")
			div
				span.b3.text-grey.mr-1(ref='price') {{ $t('forms.price') }}:
				template
					span.b3.mr-1.text-nowrap(ref='price_value' v-html="item.plan.price.priceWithCurrency()")
					span.b4.text-grey {{ $t('plan.periods.' + item.plan.period.title) }}

		.border-rounded.info-tooltip__subscription(v-if="item.promoCode")
			.d-flex.align-items-center.info-tooltip__item
				svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
					rect(width='32' height='32' rx='16' :fill="isDarkColor ? '#2C3C4B' : '#EFF7FF'")
					path(d='M23.5 14.5V13.75C23.5 10.75 22.75 10 19.75 10H12.25C9.25 10 8.5 10.75 8.5 13.75V14.125C9.535 14.125 10.375 14.965 10.375 16C10.375 17.035 9.535 17.875 8.5 17.875V18.25C8.5 21.25 9.25 22 12.25 22H19.75C22.75 22 23.5 21.25 23.5 18.25C22.465 18.25 21.625 17.41 21.625 16.375C21.625 15.34 22.465 14.5 23.5 14.5Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M13.75 18.0625L18.25 13.5625' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M18.2459 18.0625H18.2526' stroke='#52A7F9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
					path(d='M13.7459 13.9375H13.7526' stroke='#52A7F9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
				span.b1.text-bold.ml-10 {{ $t('project.promoCode')}}
			.info-tooltip__item
				span.b3.text-grey.mr-1(ref='promoCode') {{ $t('forms.name') }}:
				span.b3(ref='promoCode_value' v-html="item.promoCode.code")
			.info-tooltip__promocode
				span.b3.text-grey.mr-1.text-nowrap.info-tooltip__price(ref='priceWithPromocode') {{ $t('project.priceWithPromocode') }}:
				template
					span.b3.text-nowrap.info-tooltip__price(ref='priceWithPromocode_value' v-html="calculatePriceWithPromoCode(item).priceWithCurrency()")
					span.b3.text-grey.text-through.text-nowrap.info-tooltip__price(v-html="item.plan.price.priceWithCurrency()")
					span.text-nowrap.info-tooltip__discount -{{item.promoCode.discount}}%

		.border-rounded.info-tooltip__subscription(v-if="item.promotion")
			.d-flex.align-items-center.info-tooltip__item
				svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
					rect(width='32' height='32' rx='16' :fill="isDarkColor ? '#2C3C4B' : '#EFF7FF'")
					path(d='M23.5 14.5V13.75C23.5 10.75 22.75 10 19.75 10H12.25C9.25 10 8.5 10.75 8.5 13.75V14.125C9.535 14.125 10.375 14.965 10.375 16C10.375 17.035 9.535 17.875 8.5 17.875V18.25C8.5 21.25 9.25 22 12.25 22H19.75C22.75 22 23.5 21.25 23.5 18.25C22.465 18.25 21.625 17.41 21.625 16.375C21.625 15.34 22.465 14.5 23.5 14.5Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M13.75 18.0625L18.25 13.5625' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M18.2459 18.0625H18.2526' stroke='#52A7F9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
					path(d='M13.7459 13.9375H13.7526' stroke='#52A7F9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
				span.b1.text-bold.ml-10 {{ $t('paymentConfirm.promotion')}}
			.info-tooltip__item
				span.b3.text-grey.mr-1(ref='promotion') {{ $t('forms.name') }}:
				span.b3(ref='promotion_value' v-html="item.promotion.title")
			.info-tooltip__promocode
				span.b3.text-grey.mr-1.text-nowrap.info-tooltip__price(ref='promotionPrice') {{ $t('project.promotionPrice') }}:
				template
					span.b3.mr-2.text-nowrap.info-tooltip__price(ref='promotionPrice_value' v-html="calculatePriceWithPromotion(item).priceWithCurrency()")
					span.b3.mr-2.text-grey.text-through.text-nowrap.info-tooltip__price(v-html="item.plan.price.priceWithCurrency()")
					span.text-nowrap.info-tooltip__discount -{{item.promotion.discount}}%

		.border-rounded.info-tooltip__subscription(v-if='item.extraPrice')
			.d-flex.align-items-center.info-tooltip__item
				svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
					rect(width='32' height='32' rx='16' :fill="isDarkColor ? '#2C3C4B' : '#EFF7FF'")
					path(d='M12.1602 20.613V19.0605' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round')
					path(d='M16 20.6128V17.5078' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round')
					path(d='M19.8398 20.6123V15.9473' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round')
					path(d='M19.8402 11.3867L19.4952 11.7917C17.5827 14.0267 15.0177 15.6092 12.1602 16.3217' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round')
					path(d='M17.6426 11.3867H19.8401V13.5767' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M18.25 23.5C22 23.5 23.5 22 23.5 18.25V13.75C23.5 10 22 8.5 18.25 8.5H13.75C10 8.5 8.5 10 8.5 13.75V18.25C8.5 22 10 23.5 13.75 23.5H18.25Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
				span.b1.text-bold.ml-10 {{ $t('tutorials.payMore.title')}}
			.info-tooltip__promocode
				span.b3.text-grey.mr-1.text-nowrap.info-tooltip__price(ref='extraPrice') {{ $t('tutorials.payMore.title')}}:
				template
					span.b3.mr-2.text-nowrap.info-tooltip__price(ref='extraPrice_value' v-html="calculatePriceWithExtraPrice(item).priceWithCurrency()")
					span.b3.mr-2.text-grey.text-through.text-nowrap.info-tooltip__price(v-html="calculatePriceForPreviousValueExtraPrice(item).priceWithCurrency()")
					span.text-success.info-tooltip__discount(v-html='`+${item.extraPrice.priceWithCurrency()}`')

		.border-rounded.info-tooltip__subscription(v-if='item.comebacker && item.isPaymentPaidAfterUseComebacker()')
			.d-flex.align-items-center.info-tooltip__item
				svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
					rect(width='32' height='32' rx='16' :fill="isDarkColor ? '#2C3C4B' : '#EFF7FF'")
					path(d='M10.8098 18.167C10.6148 17.5145 10.4873 16.7945 10.4873 15.9995C10.4873 12.407 13.3973 9.49695 16.9898 9.49695C20.5823 9.49695 23.4923 12.407 23.4923 15.9995C23.4923 19.592 20.5823 22.502 16.9898 22.502C15.6548 22.502 14.4098 22.097 13.3748 21.407' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M10.99 19.0977L8.5 16.9302' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M10.9902 19.0977L12.8352 16.5702' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
				span.b1.text-bold.ml-10 {{ $t('promotions.tabs.comebacker')}}
			.info-tooltip__item
				span.b3.text-grey.mr-1(ref='comebacker') {{ $t('forms.name') }}:
				span.b3(ref='comebacker_value') {{ item.comebacker.title}}
			.info-tooltip__item.info-tooltip__promocode
				span.b3.text-grey.mr-1.text-nowrap.info-tooltip__price(ref='priceWithComebacker') {{ $t('project.priceWithComebacker') }}:
				template
					span.b3.mr-2.text-nowrap.info-tooltip__price(v-html="item.price.priceWithCurrency()" ref='priceWithComebacker_value')
					span.b3.mr-2.text-grey.text-through.text-nowrap.info-tooltip__price(v-html="calculatePriceBeforeUseComebacker(item).priceWithCurrency()")
					span.info-tooltip__discount.is-comebacker -{{item.comebacker.discount}}%
			div
				span.b3.text-grey.mr-1.text-nowrap(ref='comebackerActivation') {{ $t('project.comebackerActivationDate') }}:
				span.b3.text-nowrap(ref='comebackerActivation_value') {{ $t('date.from2') }} {{ item.comebackerAttempt.createdAt | dateDDMMMYYYY }}

		.border-rounded.info-tooltip__subscription
			.d-flex.align-items-center.info-tooltip__item
				svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
					rect(width='32' height='32' rx='16' :fill="isDarkColor ? '#2C3C4B' : '#EFF7FF'")
					path(d='M12.25 22.375C10 22.375 8.5 21.25 8.5 18.625V13.375C8.5 10.75 10 9.625 12.25 9.625H19.75C22 9.625 23.5 10.75 23.5 13.375V18.625C23.5 21.25 22 22.375 19.75 22.375H12.25Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(fill-rule='evenodd' clip-rule='evenodd' d='M16 18.25C14.7574 18.25 13.75 17.2426 13.75 16C13.75 14.7574 14.7574 13.75 16 13.75C17.2426 13.75 18.25 14.7574 18.25 16C18.25 17.2426 17.2426 18.25 16 18.25Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M11.125 14.125V17.875' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M20.875 14.125V17.875' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
				span.b1.text-bold.ml-10 {{ $t('project.profit')}}
			.info-tooltip__item
				span.b3.text-grey.mr-1(ref='paidTo') {{ $t('project.paidTo') }}:
				span
					span.b3.mr-1(v-if='item.kickAt' ref='paidTo_value') {{ item.kickAt | dateDDMMMYYYY}}
					span.b3.mr-1(v-else-if='item.kickedAt' ref='paidTo_value') {{ item.kickedAt | dateDDMMMYYYY}}
					span.b3.mr-1(v-else-if='item.nextAt' ref='paidTo_value') {{ item.nextAt | dateDDMMMYYYY}}
					span.b3(v-else-if='item.plan.isForever()' ref='paidTo_value') {{ $t('plan.periods.forever')}}
			.info-tooltip__item
				span.b3.text-grey.text-nowrap.mr-1(ref='next') {{ $t('project.nextPayment') }}:
				template
					span.b3.mr-2(:class="{'mb-2': item.isStatusTrial()}" v-if='(item.nextAt && item.nextAt == item.kickAt && !item.isOnSupportCriticalAndUnknown()) && !item.isOneTime()' ref='next_value') {{ item.nextAt | dateDDMMMYYYY }}
					span.b3.mr-2(v-if='item.isOnSupport() || item.isOnSupportCriticalAndUnknown()' v-html="$t('plan.nextPaymentInSupport')" ref='next_value')
					span.b3.mb-0.info-tooltip__tooltip(v-if='item.isOnSupport() || item.isOnSupportCriticalAndUnknown()' :id="!isMobile ? `nextPaymentNull-${item.id}` : ''" @click="isMobile ? $bvModal.show(`nextPaymentNull-${item.id}`) : ''")
						span ?
						.info-tooltip__above-tooltip(v-if="!isMobile")
							p.b4.mb-3.text-left(v-html="$t('tutorials.recoverySubscriptionDesc')" ref='next_value')
							pw-btn-link(:text="`<div class='status-dollar-circle bg-white mr-1'></div>&nbsp${$t('tutorials.recoverySubscription')}`" :href="'https://docs.paywall.pw/rus/obshee/vosstanovlenie-podpisok'" :blank="true" :tooltip="true")
					span.b3.mb-0(v-if='item && item.isCompletedSubscription() || item.nextAt == null && item.hasPaymentPaid() && !item.isOnSupport() || item.isOneTime()' ref='next_value') {{ $t('plan.nextPaymentNull') }}
			.info-tooltip__item(v-if='item.counters && item.counters.count')
				span.b3.text-grey.mr-1(ref='paymentsCount') {{ $t('project.paymentsCount') }}:
				span
					span.b3.mr-2(v-html="paymentsAmountRu(item.counters.count)" ref='paymentsCount_value')
					span.b3.text-primary.info-tooltip__link(@click="goToPayments") {{ $t('project.show') }}
			div
				span.b3.text-grey.mr-1(ref='profit') {{ $t('project.profit') }}:
				template
					span.b3(v-if='item.getProfitMerchatOtherValue() != 0' ref='profit_value' v-html="`🇷🇺 &nbsp;${item.getProfitMerchatOther()}`")
					span.b3(v-if='item.getProfitMerchatWorld2ruValue() != 0' ref='profit_value' v-html="`🌍 &nbsp;${item.getProfitMerchatWorld2ru()}`" :class='{"ml-3" : item.getProfitMerchatOtherValue() != 0}')
					span.b3(v-if='item.getProfitMerchatOtherValue() == 0 && item.getProfitMerchatWorld2ruValue() == 0' ref='profit_value' v-html="`${item.price.currency == 'RUB' ? '🇷🇺' : '🌍'} &nbsp;0&nbsp;${currency[item.price.currency]}`")
		.btn.btn-small.info-tooltip__copy-data(@click='copy') {{ $t('button.copyData') }}
</template>

<script>
import PwBtnLink from '@/components/Buttons/PwBtnLink';
import {SERVICE_STRING} from '@/utils/string';
import {CURRENCY, Money} from '@/models/money';

export default {
	name : 'InfoTooltip',
	components: {
		PwBtnLink
	},
	props: {
		item: {
			type: Object,
			default: (() => {})
		}
	},
	computed: {
		currency() {
			return CURRENCY;
		},
	},
	methods: {
		status(item) {
			if( item.isUndefined() ) return this.$t('statusesFollowers.unknown');
			if( item.isStatusTrial() ) return this.$t('statusesFollowers.trial');
			if( item.isActive() ) return this.$t('statusesFollowers.active');
			if( item.isCompleted() || item.isCancelledButNotKicked() || item.isCompletedSubscription() ) return this.$t('statusesFollowers.closed');

			return '';
		},
		calculatePriceWithPromotion(item) {
			let planPrice = Number(item.plan.price.amount);
			let amount = Math.round((planPrice/100)*item.promotion.discount);
			return new Money({amount: (planPrice - amount) + '00', currency: item.price.currency});
		},
		calculatePriceWithExtraPrice(item) {
			let price = Number(item.plan.price.amount);
			if (item.promotion) {
				price = Number(this.calculatePriceWithPromotion(item).amount);
			}
			if (item.promoCode) {
				price = Number(this.calculatePriceWithPromoCode(item).amount);
			}
			return new Money({amount: (price+Number(item.extraPrice.amount)) + '00', currency: item.price.currency});
		},
		calculatePriceForPreviousValueExtraPrice(item) {
			if (item.promotion) {
				return this.calculatePriceWithPromotion(item);
			}
			if (item.promoCode) {
				return this.calculatePriceWithPromoCode(item);
			}
			return item.plan.price;
		},
		calculatePriceWithPromoCode(item) {
			let planPrice = Number(item.plan.price.amount);
			let amount = Math.round((planPrice/100)*item.promoCode.discount);
			return new Money({amount: (planPrice - amount) + '00', currency: item.price.currency});
		},
		calculatePriceBeforeUseComebacker(item) {
			let price = Number(item.plan.price.amount);
			if (item.extraPrice && item.promotion || item.extraPrice && item.promoCode) {
				price = Number(this.calculatePriceWithExtraPrice(item).amount)
			} else if (item.promotion) {
				price = Number(this.calculatePriceWithPromotion(item).amount)
			} else if (item.extraPrice) {
				price = price+Number(item.extraPrice.amount);
			} else if (item.promoCode) {
				price = Number(this.calculatePriceWithPromoCode(item).amount);
			}
			return new Money({amount: price + '00', currency: item.price.currency});
		},
		paymentsAmountRu(v) {
			return v + ' ' + SERVICE_STRING.ru_ends(v, this.$t('project.paymentsArray'));
		},

		goToPayments() {
			this.$router.push({name: 'finance', query: {type: 'payments', search: this.item.id}});
		},
		copy () {
			let text = [];
			let id = [];
			let value = [];

			_.each(this.$refs, (r, i) => i.includes('_value') ? value.push(r.innerText) : id.push(r.innerText));
			_.each(id, (v, i) => text.push(`${v} ${value[i]}`));

			this.$copyText(text.join('\n'))
				.then( e => this.$notify("success", this.$t('success.dataCopied')))
				.catch( e => this.$notify("error", this.$t('error.dataCopied'), e));
		}
	}
}
</script>
