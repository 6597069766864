<template lang='pug'>
	.d-flex.filters
		.filters__wrap(v-if="step == 1 || !isMobile")
			.d-flex.flex-column.pr-md-4.filters__tabs
				span.b3.btn.btn-text.filters__reset-all(v-if="isMobile" @click="resetAll" :class="{'disabled': !used}") {{ $t('button.resetAll') }}

				pw-btn.filters__tab(
					v-for="(tab, i) in followersFiltersTabs"
					:key="i"
					:class="{'active-tab': tabFilters == i && !isMobile}"
					:text="tab.title"
					:icon="tab.icon"
					:quantity="tab.quantity"
					:variant="'followers-filters'"
					@click.native="tabFilters = i, step = 2, disabledAllTab, toggleModalHeaderStepClass"
				)

				b-form-checkbox.filters__checkbox.filters__pay-more(v-model="extraPrice" @change="disabledAllTab") {{ $t('tutorials.payMore.title') }}

				.d-flex.position-relative.date-picker-control(:class="{'is-float': creationDate.start}")
					v-date-picker.w-100.date-picker(v-model="creationDate" :key="creationDateKey" :min-date="new Date(project.createdAt)" :max-date="new Date()" :masks="masks" is-range :locale="currentLanguage" :popover="{ visibility: 'click' }")
						template(v-slot="{ inputValue, inputEvents, togglePopover }")
							span.icon-calendar(@click="togglePopover()")
							.d-flex.date-picker__input(@click="togglePopover()")
								input.b3.mr-1.date-picker__input-calendar(:class="{'visually-hidden': !creationDate.start && !creationDate.end}" id="creation-date-calendar" :value="inputValue.start" v-on="inputEvents.start" readonly)
								span.b3.mr-1(v-if="creationDate.start && creationDate.end") -
								span.b3.date-picker__input-calendar(v-if="creationDate.start && creationDate.end") {{ inputValue.end }}
								input.visually-hidden(:value="inputValue.end" v-on="inputEvents.end")
					label.m-0.b3.date-picker__label(:class="{'b4': creationDate.start && creationDate.end}" for="creation-date-calendar") {{ $t('project.addShort') }}
					icon-close.date-picker__reset(v-if="creationDate.start" @click="creationDate.start = null, creationDate.end = null, creationDateKey++")

				.d-flex.position-relative.date-picker-control(:class="{'is-float': completionDate.start}")
					v-date-picker.w-100.date-picker(v-model="completionDate" :key="completionDateKey" :min-date="new Date(project.createdAt)" :max-date="new Date()" :masks="masks" is-range :locale="currentLanguage" :popover="{ visibility: 'click' }")
						template(v-slot="{ inputValue, inputEvents, togglePopover}")
							span.icon-calendar(@click="togglePopover()")
							.d-flex.date-picker__input(@click="togglePopover()")
								input.b3.mr-1.date-picker__input-calendar(:class="{'visually-hidden': !completionDate.start && !completionDate.end}" id="completion-date-calendar" :value="inputValue.start" v-on="inputEvents.start" readonly)
								span.b3.mr-1(v-if="completionDate.start && completionDate.end") -
								span.b3.date-picker__input-calendar(v-if="completionDate.start && completionDate.end") {{ inputValue.end }}
								input.visually-hidden(:value="inputValue.end" v-on="inputEvents.end")
					label.m-0.b3.date-picker__label(:class="{'b4': completionDate.start && completionDate.end}" for="completion-date-calendar") {{ $t('project.completion') }}
					icon-close.date-picker__reset(v-if="completionDate.start" @click="completionDate.start = null, completionDate.end = null, completionDateKey++")

			.d-flex.justify-content-between.filters__buttons
				.btn.btn-primary.b2.filters__btn(@click="applyFilter(), isloading = true")
					.loader(v-if="isloading || isPending && !isloadingReset")
					span(v-else='') {{ $t('paymentPage.apply') }}
				.btn.btn-border.text-nowrap.b2.filters__btn(v-if="!isMobile" @click="resetAll" :class="{'disabled': !used}")
					.loader.primary(v-if="isloadingReset")
					span(v-else='') {{ $t('button.resetAll') }}

		.d-flex.flex-column.w-100.filters__tabs(v-if="step == 2 || !isMobile")
			.d-flex.justify-content-between.align-items-center.filters__header
				svg.cursor-pointer.mr-3(v-if="isMobile" @click="step = 1" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg")
					path(d="M15 19.9181L8.47997 13.3981C7.70997 12.6281 7.70997 11.3681 8.47997 10.5981L15 4.07812" stroke="#8292A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
				h3.h3.text-nowrap(v-if="isMobile") {{ followersFiltersTabs[tabFilters].title }}
				.d-flex.flex-column.w-100
					// В my-input-search условие всегда нужны только не отфильтрованные массивы
					my-input-search.mb-3(v-if="tabFilters == 1 && plans.length > 5 && !isMobile" v-model="searchPlans" :placeholder="$t('dashboards.search')")
					my-input-search.mb-3(v-if="tabFilters == 2 && promotions.length > 5 && !isMobile" v-model="searchProject" :placeholder="$t('dashboards.search')")
					my-input-search.mb-3(v-if="tabFilters == 3 && promocodes.length > 5 && !isMobile" v-model="searchPromocode" :placeholder="$t('dashboards.search')")
					my-input-search.mb-3(v-if="tabFilters == 4 && comebacks.length > 5 && !isMobile" v-model="searchComeback" :placeholder="$t('dashboards.search')")
					.d-flex.justify-content-end.justify-content-md-between.align-items-center(v-if="(tabFilters == 1 && searchedPlans.length != 0) || (tabFilters == 2 && searchedPromotions.length != 0) || (tabFilters == 3 && searchedPromocodes.length != 0) || (tabFilters == 4 && searchedComebacks.length != 0) || tabFilters == 0")
						span.b3.filters__choose-all(v-if="tabFilters == 0" :class="{'active-choose-all': statusCross}" @click="chooseAllTab") {{ $t('button.chooseAll') }}
							i.fontello.fontello-icon-cross-bold(v-if="statusCross")
						icon-close.filters__icon-close(v-if="!isMobile && tabFilters == 0" @click="$emit('click', $event)")
						span.b3.filters__choose-all(v-if="tabFilters == 1" :class="{'active-choose-all': plansCross}" @click="chooseAllTab") {{ $t('button.chooseAll') }}
							i.fontello.fontello-icon-cross-bold(v-if="plansCross")
						icon-close.filters__icon-close(v-if="!isMobile && tabFilters == 1 && plans.length < 5" @click="$emit('click', $event)")
						span.b3.filters__choose-all(v-if="tabFilters == 2" :class="{'active-choose-all': promotionsCross}" @click="chooseAllTab") {{ $t('button.chooseAll') }}
							i.fontello.fontello-icon-cross-bold(v-if="promotionsCross")
						icon-close.filters__icon-close(v-if="!isMobile && tabFilters == 2 && promotions.length < 5" @click="$emit('click', $event)")
						span.b3.filters__choose-all(v-if="tabFilters == 3" :class="{'active-choose-all': promoCodesCross}" @click="chooseAllTab") {{ $t('button.chooseAll') }}
							i.fontello.fontello-icon-cross-bold(v-if="promoCodesCross")
						icon-close.filters__icon-close(v-if="!isMobile && tabFilters == 3 && promocodes.length < 5" @click="$emit('click', $event)")
						span.b3.filters__choose-all(v-if="tabFilters == 4" :class="{'active-choose-all': comebacksCross}" @click="chooseAllTab") {{ $t('button.chooseAll') }}
							i.fontello.fontello-icon-cross-bold(v-if="comebacksCross")
						icon-close.filters__icon-close(v-if="!isMobile && tabFilters == 4 && comebacks.length < 5" @click="$emit('click', $event)")
						//span.b3.btn.btn-text(@click="resetAllTab" :class="{'disabled': disabledResetTab}") {{ $t('button.reset') }}
			b-form-checkbox.b3.position-relative.filters__tab-checkbox.filters__tab-checkbox--status(
				v-if="tabFilters == 0"
				v-for="(status, i) in $t('statusesFollowers')"
				:key="i"
				:class="{'active': i == 'active', 'trial': i == 'trial', 'completed': i == 'closed', 'unidentified': i == 'unknown'}"
				v-model="statusValue"
				:value="i"
				@change="disabledAllTab"
			)
				span.position-absolute
				| {{ status }}

			my-input-search.mb-1(v-if="tabFilters == 1 && plans.length > 5 && isMobile" v-model="searchPlans" :placeholder="$t('dashboards.search')")
			.filters__tab-checkbox-wrap
				b-form-checkbox.b3.filters__tab-checkbox(v-if="tabFilters == 1" v-model="plansValue" v-for="(plan, i) in searchedPlans" :key="i" :value="plan.id" @change="disabledAllTab") {{ plan.title }}

			my-input-search.mb-1(v-if="tabFilters == 2 && promotions.length > 5 && isMobile" v-model="searchProject" :placeholder="$t('dashboards.search')")
			.filters__tab-checkbox-wrap
				b-form-checkbox.b3.filters__tab-checkbox(v-if="tabFilters == 2" v-model="promotionsValue" v-for="(promo, i) in searchedPromotions" :key="i" :value="promo.id" @change="disabledAllTab") {{ promo.title }}

			my-input-search.mb-1(v-if="tabFilters == 3 && promocodes.length > 5 && isMobile" v-model="searchPromocodes" :placeholder="$t('dashboards.search')")
			.filters__tab-checkbox-wrap
				b-form-checkbox.b3.filters__tab-checkbox(v-if="tabFilters == 3" v-model="promoCodesValue" v-for="(promo, i) in searchedPromocodes" :key="i" :value="promo.id" @change="disabledAllTab") {{ promo.title }}

			my-input-search.mb-1(v-if="tabFilters == 4 && comebacks.length > 5 && isMobile" v-model="searchComeback" :placeholder="$t('dashboards.search')")
			.filters__tab-checkbox-wrap
				b-form-checkbox.b3.filters__tab-checkbox(v-if="tabFilters == 4" v-model="comebacksValue" v-for="(comeback, i) in searchedComebacks" :key="i" :value="comeback.id" @change="disabledAllTab") {{ comeback.title }}

			.d-flex.flex-column.m-auto(v-if="(tabFilters == 1 && searchedPlans.length == 0) || (tabFilters == 2 && searchedPromotions.length == 0) || (tabFilters == 3 && searchedPromocodes.length == 0) || (tabFilters == 4 && searchedComebacks.length == 0)")
				icon-close.filters__close(v-if="!isMobile" :class="{'stock': tabFilters == 2 && promotions.length == 0 || tabFilters == 3 && promocodes.length == 0 || tabFilters == 4 && comebacks.length == 0}" @click="$emit('click', $event)")
				h1.mb-3.text-center 😢
				span.b4.text-grey {{ $t('errors.emptySearch') }}

			.mt-auto
				.btn.btn-primary.w-100.mt-3.mt-md-0.filters__btn(v-if="isMobile" @click="step = 1")
					.loader(v-if="isloading || isPending")
					span(v-else='') {{ $t('paymentPage.apply') }}
</template>

<script>
import PwBtn from "@/components/Buttons/PwBtn";
import { mapActions, mapGetters } from 'vuex';
import IconClose from "@/components/Common/IconClose";
import { STORAGE } from "@/api/storage";
import MyInputSearch from "@/components/UI/MyInputSearch";

export default {
	name: "FollowersFilters",
	components: {
		PwBtn,
		IconClose,
		MyInputSearch
	},
	data: () => ({
		isloading: false,
		isloadingReset: false,
		tabFilters: 0,
		extraPrice: false,
		quantity: null,
		disabledResetTab: true,
		indexStatus: null,
		step: 1,
		currentLanguage: STORAGE.getLang(),
		masks: {
			input: 'DD.MM.YYYY',
			weekdays: 'WW'
		},
		searchPlans: '',
		searchProject: '',
		searchPromocode: '',
		searchComeback: '',
		statusValue: [],
		plansValue: [],
		promotionsValue: [],
		promoCodesValue: [],
		comebacksValue: [],
		creationDateKey: 0,
		completionDateKey: 0,
		creationDate: {
			start: null,
			end: null,
		},
		completionDate: {
			start: null,
			end: null,
		},
		statusCross: false,
		plansCross: false,
		promotionsCross: false,
		promoCodesCross: false,
		comebacksCross: false,
	}),
	computed: {
		...mapGetters({
			project: 'project/opened',
			promotions: 'project/promotions',
			promocodes : 'project/promocodes',
			plans: 'plans/list',
			comebacks: 'comebackers/items',
			previousData: 'modals/followersFilters',
			isPending: 'finance/isPendingSubcriptions',
		}),
		followersFiltersTabs() {
			return [
				{title: this.$t('project.status'), icon: "fontello-icon-status", quantity: this.statusValue.length},
				{title: this.$t('promotions.tabs.plans'), icon: "fontello-icon-plans", quantity: this.plansValue.length},
				{title: this.$t('promotions.tabs.stock'), icon: "fontello-icon-discount-shape", quantity: this.promotionsValue.length},
				{title: this.$t('promotions.tabs.promoCodes'), icon: "fontello-icon-ticket-discount", quantity: this.promoCodesValue.length},
				{title: this.$t('promotions.tabs.comebacker'), icon: "rotate-left", quantity: this.comebacksValue.length},
			]
		},
		used() {
			return !(this.statusValue.length == 0 && this.plansValue.length == 0 && this.promotionsValue.length == 0 && this.promoCodesValue.length == 0 && this.comebacksValue.length == 0 && this.extraPrice == false && this.creationDate.start == null && this.completionDate.start == null)
		},
		searchedPlans() {
			return this.searchPlans.length > 0 ? _.filter(this.plans, p => p.title.toLowerCase().includes(this.searchPlans.toLowerCase()) ) : this.plans
		},
		searchedPromotions() {
			return this.searchProject.length > 0 ? _.filter(this.promotions, p => p.title.toLowerCase().includes(this.searchProject.toLowerCase()) ) : this.promotions
		},
		searchedPromocodes() {
			return this.searchPromocode.length > 0 ? _.filter(this.promocodes, p => p.title.toLowerCase().includes(this.searchPromocode.toLowerCase()) ) : this.promocodes
		},
		searchedComebacks() {
			return this.searchComeback.length > 0 ? _.filter(this.comebacks, p => p.title.toLowerCase().includes(this.searchComeback.toLowerCase()) ) : this.comebacks
		}
	},
	mounted() {
		if (this.previousData && this.previousData.creationDateStore || this.previousData.completionDateStore) {
			this.creationDate = structuredClone(this.previousData.creationDateStore);
			this.completionDate = structuredClone(this.previousData.completionDateStore);
		}
	},
	methods: {
		...mapActions({
			getPromotions: 'project/promotions',
			getPromocodes: 'project/promocodes',
			getComebacks: 'comebackers/comebackersList'
		}),
		async applyFilter() {
			this.$emit('onApply', {
				status: this.statusValue,
				plans: this.plansValue,
				promotions: this.promotionsValue,
				promocodes: this.promoCodesValue,
				comebacks: this.comebacksValue,
				extraPrice: this.extraPrice,
				creationDate: this.creationDate,
				completionDate: this.completionDate,
				used: this.used
			});
			this.$store.commit('modals/followersFilters', {
				statusValue: this.statusValue,
				plansValue: this.plansValue,
				promotionsValue: this.promotionsValue,
				promoCodesValue: this.promoCodesValue,
				comebacksValue: this.comebacksValue,
				extraPrice: this.extraPrice,
				creationDateStore: structuredClone(this.creationDate),
				completionDateStore: structuredClone(this.completionDate),
			})
		},
		resetAll() {
			this.isloadingReset = true;
			this.statusValue = [];
			this.plansValue = [];
			this.promotionsValue = [];
			this.promoCodesValue = [];
			this.comebacksValue = [];
			this.extraPrice = false;
			this.creationDate = {
				start: null,
				end: null,
			},
			this.completionDate = {
				start: null,
				end: null,
			},
			this.creationDateKey++,
			this.completionDateKey++,
			this.disabledAllTab();
			this.statusCross = false;
			this.plansCross = false;
			this.promotionsCross = false;
			this.promoCodesCross = false;
			this.comebacksCross = false;
			this.applyFilter();
		},
		resetAllTab() {
			if(this.tabFilters == 0) {
				this.statusValue = [];
				this.statusCross = false;
			} else if(this.tabFilters == 1) {
				this.plansValue = [];
				this.plansCross = false;
			} else if(this.tabFilters == 2) {
				this.promotionsValue = [];
				this.promotionsCross = false;
			} else if(this.tabFilters == 3) {
				this.promoCodesValue = [];
				this.promoCodesCross = false;
			} else if(this.tabFilters == 4) {
				this.comebacksValue = [];
				this.comebacksCross = false;
			}
			this.disabledAllTab();
		},
		chooseAllTab() {
			if(this.tabFilters == 0) {
				if(this.statusValue.length != Object.keys(this.$t('statusesFollowers')).length) {
					this.statusValue = Object.keys(this.$t('statusesFollowers'));
					this.statusCross = true;
				} else {
					this.statusValue = [];
					this.statusCross = false;
				}
			} else if(this.tabFilters == 1) {
				if(this.plansValue.length != this.plans.length) {
					this.plansValue = this.plans.map(x => x.id);
					this.plansCross = true;
				} else {
					this.plansValue = [];
					this.plansCross = false;
				}
			} else if(this.tabFilters == 2) {
				if(this.promotionsValue.length != this.promotions.length) {
					this.promotionsValue = this.promotions.map(x => x.id);
					this.promotionsCross = true;
				} else {
					this.promotionsValue = [];
					this.promotionsCross = false;
				}
			} else if(this.tabFilters == 3) {
				if(this.promoCodesValue.length != this.promocodes.length) {
					this.promoCodesValue = this.promocodes.map(x => x.id);
					this.promoCodesCross = true;
				} else {
					this.promoCodesValue = [];
					this.promoCodesCross = false;
				}
			} else if(this.tabFilters == 4) {
				if(this.comebacksValue.length != this.comebacks.length) {
					this.comebacksValue = this.comebacks.map(x => x.id);
					this.comebacksCross = true;
				} else {
					this.comebacksValue = [];
					this.comebacksCross = false;
				}
			}
			this.disabledAllTab();
			this.applyFilter();
		},
		disabledAllTab() {
			if(this.tabFilters == 0 && this.statusValue == 0) return this.disabledResetTab = true;
			if(this.tabFilters == 1 && this.plansValue.length == 0) return this.disabledResetTab = true;
			if(this.tabFilters == 2 && this.promotionsValue.length == 0) return this.disabledResetTab = true;
			if(this.tabFilters == 3 && this.promoCodesValue.length == 0) return this.disabledResetTab = true;
			if(this.tabFilters == 4 && this.comebacksValue.length == 0) return this.disabledResetTab = true;

			this.disabledResetTab = false;

			if(this.tabFilters == 0) {
				if(this.statusValue.length == Object.keys(this.$t('statusesFollowers')).length) {
					this.statusCross = true;
				} else {
					this.statusCross = false;
				}
			} else if(this.tabFilters == 1) {
				if(this.plansValue.length == this.plans.length) {
					this.plansCross = true;
				} else {
					this.plansCross = false;
				}
			} else if(this.tabFilters == 2) {
				if(this.promotionsValue.length == this.promotions.length) {
					this.promotionsCross = true;
				} else {
					this.promotionsCross = false;
				}
			} else if(this.tabFilters == 3) {
				if(this.promoCodesValue.length == this.promocodes.length) {
					this.promoCodesCross = true;
				} else {
					this.promoCodesCross = false;
				}
			} else if(this.tabFilters == 4) {
				if(this.comebacksValue.length == this.comebacks.length) {
					this.comebacksCross = true;
				} else {
					this.comebacksCross = false;
				}
			}
		},
		toggleModalHeaderStepClass() {
			if(this.step == 2){
				event.target.closest('.modal-content').querySelector('.modal-header').classList.add('is-step-2');
			} else {
				event.target.closest('.modal-content').querySelector('.modal-header').classList.remove('is-step-2');
			}
		},
		setValues() {
			_.each(this.previousData, (v, i) => {
				this[i] = v;
			})
		}
	},
	created() {
		if ( !this.promocodes || this.promocodes.length == 0 ) this.getPromocodes({project: this.project.id, itemsPerPage: 5000});
		if ( !this.promotions || this.promotions.length == 0 ) this.getPromotions({project: this.project.id, itemsPerPage: 5000});
		if ( !this.comebacks || this.comebacks.length == 0 ) this.getComebacks({project: this.project.id, itemsPerPage: 5000});

		this.isloading = false;
		this.setValues();
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.filters {
	@include respond-below(sm-new) {
		flex-grow: 1;
		padding-bottom: 20px;
	}

	&__wrap {
		@include respond-below(sm-new) {
			width: 100%;
		}
	}

	&__reset-all {
		position: absolute;
		top: -45px;
		right: 16px;
	}

	&__tabs {
		&:first-child {
			width: 280px;
			min-width: 280px;
			border-right: 1px solid var(--grayscale-border);
			padding-bottom: 2px;

			@include respond-below(sm-new) {
				width: 100%;
				min-width: 100%;
				border-right: none;
			}
		}
	}

	&__header {
		margin-left: 20px;
		margin-bottom: 10.5px;
		margin-right: 19px;

		@include respond-below(sm-new) {
			position: absolute;
			top: -50px;
			left: 16px;
			right: 16px;
			background-color: var(--foreground-color);
			margin: 0;
		}
	}

	&__tab {
		margin-bottom: 15px;

		&.rotate-left::before {
			content: '';
			background: center no-repeat url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.3098 11.167C4.1148 10.5145 3.9873 9.79445 3.9873 8.99945C3.9873 5.40695 6.89731 2.49695 10.4898 2.49695C14.0823 2.49695 16.9923 5.40695 16.9923 8.99945C16.9923 12.592 14.0823 15.502 10.4898 15.502C9.15481 15.502 7.9098 15.097 6.8748 14.407' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.49 12.0977L2 9.93016' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.49023 12.0977L6.33523 9.57016' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		}
	}

	&__choose-all {
		height: 32px;
		padding: 4px 14px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px;
		border-radius: 20px;
		vertical-align: baseline;
		transition: var(--animation-time-short);
		border: 1px solid var( --grayscale-dark-line);
		cursor: pointer;
		position: relative;

		i {
			font-size: 10px;
			position: absolute;
			right: 15px;
			top: 5px;
		}

		&.active-choose-all {
			border: 1px solid transparent;
			background-color: var(--brand-bg-primary);
			padding-right: 35px;
		}
	}

	.pw-btn {
		justify-content: start !important;
		padding: 10px 18px 10px 20px;
		background-color: var(--grayscale-bg-dark-back);

		&::before {
			padding: 7px;
			font-size: 18px;
			line-height: 18px;
			color: var(--brand-primary);
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background-color: var(--foreground-color);
		}

		&:hover,
		&:active {
			svg {
				path,
				line {
					stroke: var(--brand-primary) !important;
				}
			}
		}

		span {
			margin-left: 10px;
		}

		svg {
			margin-left: auto !important;

			path,
			line {
				stroke: var(--brand-primary) !important;
			}
		}

		.pw-btn__quantity {
			box-shadow: none;
			transition: box-shadow .4s ease;
			padding: 0 11px;
			line-height: 25px !important;
			margin-right: -2px;
		}

		&:hover, &:active {
			.pw-btn__quantity {
				box-shadow: 0px 2px 8px 0px rgba(#000000, 0.08);
			}
		}

		&.active-tab {
			background-color: var(--brand-bg-primary);

			&:hover,
			&:active {
				background-color: var(--brand-bg-primary);
				box-shadow: none;

				.pw-btn__quantity {
					background-color: var(--grayscale-dark-white-back);
					box-shadow: none;
				}
			}
		}
	}

	&__checkbox {
		padding: 0;
		min-height: 52px;
		color: var(--main-text-color);
		background-color: var(--grayscale-bg-dark-back);
		border-radius: var(--border-radius-rounded);
		transition: var(--animation-time-short);

		&:hover,
		&:active {
			background-color: var(--background-color);
			box-shadow: var(--btn-box-shadow);
		}

		.custom-control-label {
			padding: 18px 20px;
			font-size: 16px;

			&::before {
				top: 50%;
				left: auto;
				right: 19px;
				transform: translateY(-50%);
				width: 24px;
				height: 24px;
			}
		}

		.custom-control-input:checked ~ .custom-control-label {
			&::after {
				top: 50%;
				left: auto;
				right: 19px;
				transform: translateY(-50%);
				width: 24px;
				height: 24px;
			}
		}
	}

	&__tab-checkbox-wrap {
		max-height: 468px;
		overflow-y: auto;

		@include respond-below(sm-new) {
			max-height: 100%;
			overflow-y: visible;
		}
	}

	&__tab-checkbox {
		padding: 0;
		min-height: 47px;
		position: relative;

		@include respond-below(sm-new) {
			min-height: 54px;
			display: flex;
			align-items: center;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 20px;
			height: 0.5px;
			background-color: var(--grayscale-border);
			width: calc(100% - 40px);

			@include respond-below(sm-new) {
				width: 100%;
				left: 0;
			}
		}

		.custom-control-label {
			height: 100%;
			padding: 15px 80px 15px 30px;
			font-size: 16px;
			line-height: calc(24 / 16) !important;
			transition: background-color .4s ease;

			@include respond-below(sm-new) {
				padding: 15px 49px 15px 0;
				width: 100%;
				line-height: calc(16 / 16) !important;
			}

			&:hover {
				background-color: var(--background-color);
			}

			&::before {
				top: 50%;
				left: auto;
				right: 31px;
				transform: translateY(-50%);
				width: 23px;
				height: 23px;
				border-radius: 8px;
				background-color: transparent;

				@include respond-below(sm-new){
					right: 0;
				}
			}
		}

		.custom-control-input:checked ~ .custom-control-label {
			&::after {
				top: 50%;
				left: auto;
				right: 31px;
				transform: translateY(-50%);
				width: 23px;
				height: 23px;

				@include respond-below(sm-new){
					right: 0;
				}
			}
		}

		&--status {
			.custom-control-label {
				padding: 19px 26px 19px 48px;

				@include respond-below(sm-new) {
					padding: 15px 26px 15px 18px;
				}

				span {
					top: 50%;
					left: 30px;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					transform: translateY(-50%);

					@include respond-below(sm-new){
						left: 0;
					}
				}
			}

			&.active {
				.custom-control-label {
					color: var(--brand-success);

					span {
						background-color: var(--brand-success);
					}
				}
			}

			&.trial {
				.custom-control-label {
					color: var(--brand-primary);

					span {
						background-color: var(--brand-primary);
					}
				}
			}

			&.completed {
				.custom-control-label {
					color:  var(--grayscale-color-grey);

					span {
						background-color: var(--grayscale-color-grey);
					}
				}
			}

			&.unidentified {
				.custom-control-label {
					color: var(--brand-orange);

					span {
						background-color: var(--brand-orange);
					}
				}
			}
		}
	}

	.my-input-search__input {
		height: 45px;
		font-size: 14px !important;
		line-height: 22px !important;
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
	}

	&__btn {
		width: calc(50% - 2.5px);
		height: 44px;

		@include respond-below(sm-new) {
			height: 50px !important;
			font-size: 15px !important;
			line-height: 24px !important;
			width: 100%;
		}
	}

	.date-picker-control {
		margin-bottom: -2px;
		margin-top: 16px;

		.icon-calendar {
			width: 20px;
			height: 20px;
		}

		&.is-float {
			.date-picker__input {
				padding-top: 25px;
			}
		}
	}

	&__buttons {
		margin-top: 26px;
		width: calc(100% - 21px);

		@include respond-below(sm-new) {
			width: 100%;
		}
	}

	&__icon-close {
		margin-top: -7px;
	}
}
</style>
